import { useSearchParams, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useAlert } from "../../context/AlertContext";
import './index.css';

function EditPublicCard ({subjectDetails}) {
    const [ searchParams ] = useSearchParams();
    const subjectId = searchParams.get('subjectId');
    const [ subjectTitle, setSubjectTitle ] = useState('');
    const [ subjectColor, setSubjectColor ] = useState('');
    const [ textColor, setTextColor ] = useState('rgb(15, 51, 71');
    const [ tags, setTags ] = useState([]);
    const [ loggedIn, setLoggedIn ] = useState([]);
    const { triggerAlert } = useAlert();
    const navigate = useNavigate();

    useEffect(() => {
        async function authenticate () {
            try {
                const response = await fetch('https://api.flashcard-revision.com/user/authenticate', {credentials: 'include'});
                if (response.status === 401) { navigate('/login'); }
                else if (!response.ok) {
                    const error = await response.json();
                    throw new Error(error.message);
                } else { setLoggedIn(true); };
            } catch (err) { triggerAlert(err.message, 'error'); };
        }
        authenticate();
    }, []);

    useEffect(() => {
        setSubjectTitle(subjectDetails.name);
        setSubjectColor(subjectDetails.color);
        if (subjectDetails.tags) setTags(subjectDetails?.tags.split(','));
        getTextColor(subjectDetails.color);
    }, [subjectDetails.subject_id]);

    function getTextColor (color) {
        setTextColor(color === 'grey' || color === 'green' || 
            color === 'red' || color === 'blue'
           ? 'white' : 'rgb(15, 51, 71)');
    }

    async function handleDelete () {
        // Confirm Deletion
        const confirmed = window.confirm('Are you sure you want to delete this subject?');
        if (!confirmed) return;
        try {
            const body = {subjectId};
            const options = {method: 'POST', credentials: 'include', headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body)};
            const response = await fetch('https://api.flashcard-revision.com/user/subjects/delete', options);
            if (!response.ok) { 
                const error = await response.json();
                throw new Error(error.message);
            } else {
                const result = await response.json();
                triggerAlert(result.message, 'success');
                navigate('/subjects');
            }
        } catch (err) { triggerAlert(err.message, 'error')};
    }

    function tagInputs () {
        return (
            <div className="edit-public-subject-tags-container" style={{maxWidth: '80%'}}>
                <p style={{color: textColor}}>Tags: </p>
                {tags.map((input, index) => {
                    return <span style={{color: textColor, padding: 4}} key={index}>{input}</span>
                })}
            </div>
        );
    } 

    if (loggedIn) {
        return (
            <li className="item edit-subject-card" style={{backgroundColor: subjectColor}}>
                <div className="edit-subject-flashcard" style={{color: textColor}}>
                    <div className="subject-title-container">
                        <h3 className="subject-title-text" style={{color: textColor}}>{subjectTitle}</h3>
                    </div>
                    {tagInputs()}
                    <div className="subject-edit-public-delete-container">
                        <button onClick={handleDelete}>Remove from your collection</button>
                    </div>   
                </div>
            </li>
        )
    }
}

export default EditPublicCard;