import { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useAlert } from '../../context/AlertContext';
import Title from '../../components/pageTitle';
import './index.css';

function CreateSubject () {
    const [ subjectName, setSubjectName] = useState('');
    const [ subjectColor, setSubjectColor] = useState('#ccc');
    const [ textColor, setTextColor ] = useState('rgb(15, 51, 71)');
    const [ isPrivate, setIsPrivate ] = useState(true);
    const [ tags, setTags ] = useState([]);
    const { triggerAlert } = useAlert();
    const navigate = useNavigate();

    useEffect(() => {
        const tagsArray = new Array(3).fill('');
        setTags(tagsArray);
    }, []);

    function handleNameChange ({target}) {
        if (target.value.length === 50) return;
        setSubjectName(target.value);
    }

    function handleColorChange ({target}) {
        const color = target.value;
        setSubjectColor(color);
        changeTextColor(color);
    }

    function changeTextColor (color) {
        const textColor = color === 'grey' || color === 'green' || 
               color === 'red' || color === 'blue'
               ? 'white' : 'rgb(15, 51, 71)';
        setTextColor(textColor);
    }

    function updateTags ({target}) {
        const targetIndex = target.getAttribute('data-index');
        const updatedTags = [...tags];
        updatedTags[targetIndex] = target.value;
        setTags(updatedTags);
    }

    function tagInputs () {
        return (
            <div className="new-subject-tags-container">
                <p>Tags: </p>
                {tags.map((input, index) => {
                    if (index === 0) return <input type="text" data-index={index} value={input} onChange={updateTags} key={index}></input>
                    else if (tags[index-1]) {;
                        return <input type="text" data-index={index} value={input} onChange={updateTags} key={index}></input>
                    } else return null
                })}
            </div>
        );
    } 

    function handleSetPrivate () {
        setIsPrivate(!isPrivate)
    }

    async function saveSubject () {
        try {
            if (subjectName.length < 3 || subjectName.length > 30) {
                triggerAlert('Please provide a subject title between 3 and 30 characters in length', 'error');
            } else if (subjectColor !== 'pink' && subjectColor !== 'grey' && subjectColor !== 'green' && subjectColor !== 'yellow' && subjectColor !== 'red' && subjectColor !== 'blue') {
                triggerAlert('Please provide a valid subject color', 'error');
            } else if (typeof isPrivate !== 'boolean') {
                triggerAlert('Please provide a valid value for subject share status', 'error');
            } else {
                const body = {subjectName, subjectColor, tags, isPrivate};
                const options = {method: 'POST', credentials: 'include', headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body)};
                const response = await fetch('https://api.flashcard-revision.com/user/subject/new', options);
                if (response.status === 401) { navigate('/login') }
                else if (!response.ok) { 
                    const error = await response.json();
                    throw new Error(error.message);
                }
                else {
                    const result = await response.json();
                    triggerAlert(result.message, 'success');
                    navigate('/subjects');
                }
            }
        } catch (err) { triggerAlert(err.message, 'error'); };
    }

    return (
        <div className="new-subject-form-container">
            <Title title="Create Subject"/>
            <div className="new-subject-form" style={{color: textColor, backgroundColor: subjectColor}}>
                <input type="text" className="new-subject-name-input" value={subjectName} placeholder="Subject Name" onChange={handleNameChange}></input>
                {tagInputs()}
                <div className="new-subject-radio-container">
                    <label htmlFor="subjectColor">Color:</label>
                    <div className="red">
                        <input className="subject-color" type="radio" name="subjectColor" value="red" onClick={handleColorChange}></input>
                    </div>
                    <div className="pink">
                        <input className="subject-color pink" type="radio" name="subjectColor" value="pink" onClick={handleColorChange}></input>
                    </div>
                    <div className="green">
                        <input className="subject-color green" type="radio" name="subjectColor" value="green" onClick={handleColorChange}></input>
                    </div>
                    <div className="blue">
                        <input className="subject-color blue" type="radio" name="subjectColor" value="blue" onClick={handleColorChange}></input>
                    </div>
                    <div className="yellow">
                        <input className="subject-color yellow" type="radio" name="subjectColor" value="yellow" onClick={handleColorChange}></input>
                    </div>
                    <div className="grey">
                        <input className="subject-color grey" type="radio" name="subjectColor" value="grey" onClick={handleColorChange}></input>
                    </div>
                </div>
                <div className="shared-status-container">
                    <label htmlFor="sharedStatus">Private: </label>
                    <input type="checkbox" id="sharedStatus" checked={isPrivate} style={{accentColor: textColor}} onChange={handleSetPrivate}></input>
                </div>
                <button className="cancel-new-subject-btn"><NavLink to="/subjects" className="cancel-new-subject-link">Go back</NavLink></button>
                <button className="new-subject-save-btn" onClick={saveSubject}>Save</button>
            </div>
        </div>
    );
};

export default CreateSubject;