import { useSearchParams, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useAlert } from "../../context/AlertContext";
import Flashcard from "../../components/flashcards";
import Title from "../../components/pageTitle";
import './index.css';

function SearchedSubject () {
    const [ searchParams ] = useSearchParams();
    const subject_id = searchParams.get('subjectId');
    const [ flashcards, setFlashcards ] = useState([]);
    const [ loggedIn, setLoggedIn ] = useState(false);
    const [ subjectDetails, setSubjectDetails ] = useState({});
    const { triggerAlert } = useAlert();
    const navigate = useNavigate();

    useEffect(() => {
        async function authenticate () {
            try {
                const response = await fetch('https://api.flashcard-revision.com/user/authenticate', {credentials: 'include'});
                if (response.status === 401) navigate('/login');
                else if (!response.ok) {
                    const error = await response.json();
                    throw new Error(error.message);
                } else setLoggedIn(true);
            } catch (err) { triggerAlert(err.message, 'error'); };
        };
        authenticate();
    }, []);

    useEffect(() => {
        async function fetchData () {
            try {
                const response = await fetch(`https://api.flashcard-revision.com/user/subjects/flashcards?subjectId=${subject_id}`, {credentials: 'include'});
                if (response.status === 401) navigate('/login')
                else if (!response.ok) {
                    const error = await response.json();
                    throw new Error(error.message);
                } else {
                    const result = await response.json();
                    setFlashcards(result.data);
                }
            } catch (err) { triggerAlert(err.message, 'error'); };
        }
        fetchData();
    }, []);

    useEffect(() => {
        async function fetchData () {
            try {
                const response = await fetch (`https://api.flashcard-revision.com/user/subjects/details?subjectId=${subject_id}`, {credentials: 'include'});
                if (response.status === 401) navigate('/login');
                else if (!response.ok) {
                    const error = await response.json();
                    throw new Error(error.message);
                } else {
                    const result = await response.json();
                    setSubjectDetails(result.data);
                }
            } catch (err) { triggerAlert(err.message, 'error'); };
        }
        fetchData();
    }, []);

    useEffect(() => {
        const bmcButton = document.getElementsByClassName('bmc-btn-container')[0];
        bmcButton.style.display = 'none';
        return () => { bmcButton.style.display = 'block'; };
    }, [])

    function displayFlashcards () {
        return flashcards.map((flashcard, index) => {
            return (
                <Flashcard key={index} flashcardDetails={flashcard} editable={false} bgColor={subjectDetails.color} numberedItem={index}/>
            )
        });
    }

    function handleClickPrevious () {
        let item = document.querySelector(".item");
        let itemWidth = item.offsetWidth;
        document.querySelector(".list").scrollBy({ left: -itemWidth, behavior: "smooth" });
    }

    function handleClickNext () {
        let item = document.querySelector(".item");
        let itemWidth = item.offsetWidth;
        document.querySelector(".list").scrollBy({ left: itemWidth, behavior: "smooth" });
    }

    function goBack () {
        navigate(-1);
    }

    async function addSubject () {
        try {
            const body = {subjectId: subject_id};
            const options = {method: 'POST', credentials: 'include', headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body)};
            const response = await fetch('https://api.flashcard-revision.com/user/subjects/add', options);
            if (response.status === 401) navigate('/login')
            else if (!response.ok) {
                const error = await response.json();
                throw new Error(error.message);
            } else {
                const result = await response.json();
                triggerAlert(result.message, 'success');
                navigate(-1);
            }
        } catch (err) { triggerAlert(err.message, 'error'); }; 
    }

    if (loggedIn) {
        return (
            <div className="searched-subject-top-container">
                <div className="search-subject-flashcards"> 
                    <Title title={subjectDetails?.name}/>
                    <ul className="list">   
                        {displayFlashcards()}
                    </ul>
                    <p className="navbutton previous" type="button" onClick={handleClickPrevious}></p>
                    <p className="navbutton next" type="button" onClick={handleClickNext}></p>
                </div>
                <div className="searched-subject-buttons-container">
                    <button onClick={goBack} className="searched-subject-back-button">Back to search</button>
                    <button onClick={addSubject} className="searched-subject-add-button">Add to collection</button>
                </div>
            </div>
        );
    }
}

export default SearchedSubject;