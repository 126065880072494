import { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import Timer from "../../components/timer";
import CurrentFlashcard from "../../components/currentFlashcard";
import { useAlert } from "../../context/AlertContext";
import ProgressBar from "../../components/progressBar/ProgressBar";
import './index.css';

function RunFlashcards () {
    const [ searchParams ] = useSearchParams();
    const subjectId = searchParams.get('subjectId');
    const [ flashcards, setFlashcards ] = useState([]);
    const [ currentFlashcard, setCurrentFlashcard ] = useState({});
    const [ score, setScore ] = useState(0);
    const [ totalCards, setTotalCards ] = useState(0);
    const [ sessionComplete, setSessionComplete ] = useState(false);
    const [ loggedIn, setLoggedIn ] = useState(false);
    const [ bgColor, setBgColor ] = useState('');
    const [ textColor, setTextColor ] = useState('');
    const [currentProgression, setCurrentProgression ] = useState(0);
    const { triggerAlert } = useAlert();
    const navigate = useNavigate();

    useEffect(() => {
        setSessionComplete(false);
    }, []);

    useEffect(() => {
        // Load flashcards, then
        async function fetchData () {
            try {
                const response = await fetch(`https://api.flashcard-revision.com/user/subjects/flashcards?subjectId=${subjectId}`, {credentials: 'include'});
                if (response.status === 401) { navigate('/login') }
                else if (!response.ok) {
                    const error = await response.json(); 
                    throw new Error(error.message); 
                }
                else {
                    const result = await response.json();
                    if (result.data.length === 0) {
                        triggerAlert('This subject has no flashcards');
                        navigate('/subjects/');
                    } else {
                        setTotalCards(result.data.length);
                        setFlashcards()
                        setLoggedIn(true);
                        setFlashcards(result.data);
                    }
                }
            } catch (err) { 
                triggerAlert(err.message, 'error');
            }; 
        }
        fetchData();
    }, []);

    useEffect(() => {
        async function fetchData () {
            try {
                const response = await fetch(`https://api.flashcard-revision.com/user/subjects/details?subjectId=${subjectId}`, {credentials: 'include'});
                if (response.status === 401) navigate('/login');
                else if (!response.ok) {
                    const error = await response.json();
                    throw new Error(error.message);
                } else {
                    const result = await response.json();
                    setBgColor(result.data.color);
                }
            } catch (err) { triggerAlert(err.message); };
        }
        fetchData();
    }, []);

    useEffect(() => {
        getTextColor()
    }, [bgColor]);

    useEffect(() => {
        setCurrentFlashcard(flashcards[Math.floor(Math.random() * flashcards.length)]);
    }, [flashcards]);

    useEffect(() => {
        async function sendSessionData () {
            try {
                if (sessionComplete) {
                    const body = {subjectId, score: calculateScore()};
                    const options = {method: 'POST', credentials: 'include', headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body)};
                    const response = await fetch('https://api.flashcard-revision.com/user/subjects/update-score', options);
                    if (response.status === 401) { navigate('/login'); }
                    else if (!response.ok) { 
                        const error = await response.json();
                        throw new Error(error.message); 
                    } else {
                        const result = await response.json();
                        triggerAlert(result.message, 'success');
                    }
                }
            } catch (err) { triggerAlert(err.message, 'error'); }; 
        }
        sendSessionData();
    }, [sessionComplete]);

    useEffect(() => {
        const bmcButton = document.getElementsByClassName('bmc-btn-container')[0];
        bmcButton.style.display = 'none';
        return () => { bmcButton.style.display = 'block'; };
    }, [])

    function calculateScore () {
        return Math.floor((score / totalCards) * 100);
    };

    function getTextColor () {
        setTextColor(bgColor === 'grey' || bgColor === 'green' || 
               bgColor === 'red' || bgColor === 'blue'
               ? 'white' : 'rgb(15, 51, 71)');
    };

    async function nextQuestion () {
        try {
            if (flashcards.length !== 1) {
                setCurrentProgression(currentProgression => currentProgression + 1);
                setFlashcards(flashcards => flashcards.filter((flashcard) => flashcard.flashcard_id !== currentFlashcard.flashcard_id));
            } else {
                setSessionComplete(true);
            }
        } catch (err) { triggerAlert(err.message, 'error'); };
    }

    async function updateScore () {
        try {
            setScore(score => score + 1);
            nextQuestion();
        } catch (e) { triggerAlert('There was an error updating your score', 'error'); };
    }

    function message () {
        let totalScore = calculateScore();
        if (totalScore === 100) return 'Congratulations!!!'; 
        else if (totalScore >= 85) return 'Good Job!!';
        else if (totalScore < 50) return 'Nice! You could do better though!';
        else return 'Good Job!';
    }

    if (loggedIn) {
        if (sessionComplete) {
            return (
                <div className="flashcard-complete-container">
                    <div className="complete-subject-card" style={{backgroundColor: bgColor, color: textColor}}>
                        <p className="complete-card-title">Session Complete!</p>
                        <p className="complete-card-message">{message()}</p>
                        <p className="complete-card-score">Final Score: {calculateScore()}%</p>
                        <button className="complete-card-restart-btn" onClick={() => navigate(0)}>Restart</button>
                        <button className="complete-card-subjects-btn" onClick={() => navigate('/subjects')}>Subjects</button>
                    </div>
                </div>
            )
        } else {
            return (
                <div className="running-flashcards-container">
                    <p className="running-flashcards-score">Score: {Math.floor(calculateScore())}%</p>
                    <Timer />
                    <ProgressBar currentProgression={currentProgression} total={totalCards}/>
                    <CurrentFlashcard 
                        flashcard_type={currentFlashcard?.flashcard_type}
                        question={currentFlashcard?.question}
                        answer={currentFlashcard?.flashcard_type === 'Multiple Answer' ? currentFlashcard?.answer.split(',') : currentFlashcard?.answer}
                        next={nextQuestion}
                        updateScore={updateScore}
                        bgColor={bgColor}
                        textColor={textColor}
                    />
                </div>
            )
        }
    }
}

export default RunFlashcards;