import { useState, useEffect } from 'react'; 
import { useAlert } from '../../context/AlertContext';
import magnifying from "../../images/searchbar.png"
import { useNavigate } from 'react-router-dom';
import './index.css';

function SearchBar () {
    const [ term, setTerm ] = useState('');
    const navigate = useNavigate();
    const { triggerAlert }= useAlert();

    useEffect(() => {
        setTerm('');
    }, [])

    function updateSearchTerm ({target}) {
        const searchTerm = target.value;
        if (searchTerm.includes(' ')) return;
        setTerm(searchTerm);
    }

    function submitSearch (event) {
        event.preventDefault();
        if (term.length === 0) {
            triggerAlert('Please provide a valid search term', 'error');
        } else {
            navigate(`/subjects/search?term=${term}`);
            navigate(0);
        }
    }   

    return (
        <form id="searchbar-form">
            <input type="search" value={term} onChange={updateSearchTerm} placeholder="Search"/>
            <button type="submit" onSubmit={submitSearch} onClick={submitSearch} id="magnifying-glass-container"><img className="magnifying-glass" alt="magnifying glass for search component" src={magnifying}/></button>
        </form>
    )
}

export default SearchBar;