import { useState, useEffect } from 'react';
import eye from '../../images/eye.png';
import closedEye from '../../images/close-eye.png'
import './index.css';

function Flashcards ({flashcardDetails, handleDelete, bgColor, editable, isOwner, numberedItem}) {
    const [ type, setType ] = useState();
    const [ question, setQuestion ] = useState();
    const [ answer, setAnswer ] = useState();
    const [ flashcardId, setFlashcardId ] = useState();
    const [ answerIsVisible, setAnswerIsVisible ] = useState(false);
    const [ backgroundColor, setBackgroundColor ] = useState('');
    const [ textColor, setTextColor ] = useState('white');
    const [ isEditable, setIsEditable ] = useState(false);
    const [ owner, setOwner] = useState(false);
    const [ itemIndex, setItemIndex ] = useState(0)
 
    useEffect(() => {
        setType(flashcardDetails.flashcard_type);
        setQuestion(flashcardDetails.question);
        setAnswer(flashcardDetails.flashcard_type === 'Multiple Answer' ? flashcardDetails.answer.split(',') : flashcardDetails.answer);
        setFlashcardId(flashcardDetails.flashcard_id);
        setItemIndex(numberedItem);
    }, [flashcardDetails.flashcardId]);

    useEffect(() => {
        setBackgroundColor(bgColor);
        getTextColor(bgColor);
    }, [bgColor]);

    useEffect(() => {
        setIsEditable(editable);
    }, [editable]);

    useEffect(() => {
        setOwner(isOwner)
    }, [isOwner]);

    function getTextColor (color) {
        setTextColor(color === 'grey' || color === 'green' || 
            color === 'red' || color === 'blue'
            ? 'white' : 'rgb(15, 51, 71)');
    }

    function toggleAnswerVisibility () {
        setAnswerIsVisible(answerIsVisible ? false : true);
    }

    return (
        <li className={`flashcard-item item ${numberedItem === 0 ? 'firstItem' : ''}`} style={{backgroundColor: backgroundColor, color: textColor}}>
            <div className="flashcard-item-container">
                {(isEditable && owner) ? <button className="flashcard-item-button delete" onClick={() => handleDelete({flashcardId: flashcardId})}>X</button> : null}
                <p className="flashcard-item-type" style={{color: textColor}}>Type: {type}</p>
                <p className="flashcard-item-question" style={{color: textColor}}>{question}</p>
                {type === 'Fill-In-The-Gap' ? null : (
                    <div className="flashcard-answer-container">
                        {answerIsVisible ? <p className="flashcard-item-answer" style={{color: textColor}} onClick={toggleAnswerVisibility}>{type === 'Multiple Answer' ? answer.join(', ') : answer}</p> : <p onClick={toggleAnswerVisibility} style={{color: textColor}}>View Answer</p>}
                        {answerIsVisible ? 
                        <img src={closedEye} onClick={toggleAnswerVisibility} alt="An eye with a strike through"></img> :
                        <img src={eye} onClick={toggleAnswerVisibility} alt="An eye"></img>}
                    </div>
                )}
            </div>
        </li>
    );
};

export default Flashcards;