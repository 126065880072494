import { useEffect, useState } from 'react';
import './index.css';

function Timer () {
    const [ timer, setTimer ] = useState(0);
    const date = new Date(null);

    useEffect(() => {
        const timerInterval = setInterval(() => {
            setTimer(timer => timer + 1)
        }, 1000);
        return () => clearInterval(timerInterval);
    }, []);

    function displayTime () {
        date.setSeconds(timer);
        return date.toISOString().slice(11,19);
    };

    return <p className="current-time">{displayTime()}</p>
};

export default Timer;