import Navigation from '../navigation/index.js';
import { Outlet } from 'react-router-dom';
import Footer from '../footer/index.js';

function Root () {
    return (
        <>
            <Navigation />
            <Outlet />
            <Footer />
        </>
    );
};

export default Root;