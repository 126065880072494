import React from 'react';
import { useAlert } from '../../context/AlertContext';
import './index.css';

function CustomAlert () {
    const { message, bgColor, clearAlert } = useAlert();

    if (!message) return null;

    return (
        <div className="alertBox" style={{backgroundColor: bgColor}}>
            <button onClick={clearAlert}>X</button>
            <p>{message}</p>
        </div>
    )
};

export default CustomAlert;