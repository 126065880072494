import { useEffect, useState } from 'react';
import './index.css';

function ProgressBar ({currentProgression, total}) {
    const [ totalProgress, setTotalProgress ] = useState(0);

    useEffect(() => {
        const progressElement = document.getElementById('progress');
        const progress = Math.floor((currentProgression / total * 100)); 
        setTotalProgress(progress);
        progressElement.style.width = `${progress}%`;
    }, [currentProgression]);

    return (
        <div className="progressBarContainer">
            {totalProgress > 0 ? <div id="progress">{totalProgress}%</div> : <div id="progress" style={{minWidth: '100%', backgroundColor: 'transparent', color: 'black', textAlign: 'center'}}>0%</div>}
        </div>
    )
};

export default ProgressBar;