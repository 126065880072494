import { useState, useEffect } from 'react';
import { useAlert } from '../../context/AlertContext';
import { useNavigate, NavLink } from 'react-router-dom';
import './index.css';
import eye from '../../images/eye.png';
import closedEye from '../../images/close-eye.png';

function Login () {
    const [ newUser, setNewUser ] = useState(false);
    const [ email, setEmail ] = useState('');
    const [ password, setPassword ] = useState('');
    const [ confirmedPassword, setConfirmedPassword ] = useState('');
    const [ isPasswordVisible, setIsPasswordVisible ] = useState(false);
    const [ isConfirmedPasswordVisible, setIsConfirmedPasswordVisible ] = useState(false);
    const navigate = useNavigate();
    const { triggerAlert } = useAlert();
    const [ loggedIn, setLoggedIn ] = useState(false);

    useEffect(() => {
        async function checkAuth () {
            try {
                const response = await fetch('https://api.flashcard-revision.com/user/authenticate', {credentials: 'include'});
                if (response.status === 401) return;
                else if (!response.ok) { 
                    const error = await response.json();
                    throw new Error(error.message);
                }
                else { 
                    setLoggedIn(true);
                    navigate('/subjects'); 
                };
            } catch (err) { triggerAlert(err.message); };
        }
        checkAuth();
    }, []);

    function handleSetUser () {
        setNewUser(!newUser);
    }

    function handleUpdateEmail ({target}) {
        setEmail(target.value);
    }

    function handleUpdatePassword ({target}) {
        setPassword(target.value);
    }

    function handleUpdateConfirmedPassword ({target}) {
        setConfirmedPassword(target.value);
    }

    async function handleLogin () {
        try {
            if (email.length === 0 || !email.includes('@')) {
                triggerAlert('Please provide a valid email address', 'error');
            } else if (password.length < 8) {
                triggerAlert('Please provide a valid password', 'error');
            } else if (newUser && confirmedPassword !== password) {
                triggerAlert('The passwords you have provided do not match!', 'error');
            } else {
                const body = {email, password, confirmedPassword };
                const options = {method: 'POST', credentials: 'include', headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body)};
                const url = newUser ? 'https://api.flashcard-revision.com/user/signup' : 'https://api.flashcard-revision.com/user/login';
                const response = await fetch(url, options);
                if (!response.ok) { 
                    const error = await response.json();
                    throw new Error(error.message); 
                } else if (!newUser) { 
                    triggerAlert('Logged in successfully!', 'success');
                    navigate('/subjects');
                }
                else {
                    triggerAlert('Account successfully created!', 'success');
                    navigate('/subjects')
                }
            }
        } catch (err) { triggerAlert(err.message, 'error'); };
    }

    function changePasswordVisibility () {
        setIsPasswordVisible(!isPasswordVisible);
    }

    function changeConfirmedPasswordVisibility () {
        setIsConfirmedPasswordVisible(!isConfirmedPasswordVisible);
    }

    if (!loggedIn) return (
        <div className="login-container">
            <div className="login-form">
                <h4>Login</h4>
                <div>
                    <input className="login-email-input" type="email" placeholder="Email Address" value={email} onChange={handleUpdateEmail}></input>
                </div>
                <div>
                    <input className="login-password-input" type={isPasswordVisible ? 'text' : 'password'} placeholder="Password" value={password} onChange={handleUpdatePassword}></input>
                    <img className="password-img-login first" onClick={changePasswordVisibility} src={isPasswordVisible ? closedEye : eye} style={{height: (isPasswordVisible ? '10px' : 'initial')}} alt="Open/closed eye for viewing password"/>
                </div>
                {!newUser ? <NavLink to="/login/reset" className="password-reset-link">Forgot Password?</NavLink> : null}
                <div>
                    {newUser ? <input type={isConfirmedPasswordVisible ? 'text' : 'password'} placeholder="Confirm Password" value={confirmedPassword} onChange={handleUpdateConfirmedPassword}></input> : null}
                    {newUser ? <img className="password-img-login second" onClick={changeConfirmedPasswordVisibility} src={isConfirmedPasswordVisible ? closedEye : eye} style={{height: (isConfirmedPasswordVisible ? '10px' : 'initial')}} alt="Open/closed eye for viewing password"/> : null}
                </div>
                {newUser ? <button onClick={handleLogin}>Sign Up</button> : <button onClick={handleLogin}>Login</button>}
                {!newUser ? <p>Not yet registered? <span onClick={handleSetUser}>Sign up now!</span></p> : <p>Already registered? <span onClick={handleSetUser}>Login now!</span></p>}
            </div>
        </div>
    )
}

export default Login;