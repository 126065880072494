import { useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useAlert } from '../../context/AlertContext';
import eye from '../../images/eye.png';
import closedEye from '../../images/close-eye.png';
import Title from '../../components/pageTitle';
import './index.css';


function PasswordReset () {
    const [ newPassword, setNewPassword ] = useState('');
    const [ confirmedPassword, setConfirmedPassword ] = useState('');
    const [ newPasswordIsVisible, setNewPasswordIsVisible ] = useState(true);
    const [ confirmedPasswordIsVisible, setConfirmedPasswordIsVisible ] = useState(false);

    const [ searchParams ] = useSearchParams();
    const navigate = useNavigate();
    const { triggerAlert } = useAlert();

    const token = searchParams.get('token');
    const email = searchParams.get('email');

    function handlePasswordChange ({target}) {
        setNewPassword(target.value);
    }

    function handleConfirmedPasswordChange ({target}) {
        setConfirmedPassword(target.value);
    }

    function getNewPasswordType () {
        return newPasswordIsVisible ? 'text' : 'password';
    }

    function getConfirmedPasswordType () {
        return confirmedPasswordIsVisible ? 'text' : 'password';
    }

    function toggleNewPasswordVisibility () {
        return setNewPasswordIsVisible(visibility => !visibility);
    }

    function toggleConfirmedPasswordVisibility () {
        return setConfirmedPasswordIsVisible(visibility => !visibility);
    }

    async function handleClick () {
        try {
            const body = {email, token, newPassword, confirmedPassword};
            const options = {method: 'POST', credentials: 'include', headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body)};
            const response = await fetch('https://api.flashcard-revision.com/user/password/reset', options);
            if (!response.ok) {
                const error = await response.json();
                throw new Error(error.message);
            } else {
                const result = await response.json();
                triggerAlert(result.message, 'success');
                navigate('/login');
            }
        } catch (e) {
            triggerAlert(e.message, 'error');
        }
    }

    return (
        <div className="password-reset-form-container">
            <Title title="Reset Your Password"/>
            <div className="password-reset-password-form">
                <label htmlFor="new-reset-password" className="reset-password-new-pswd-label">New Password: <span className="required">*</span></label>
                <input type={getNewPasswordType()} value={newPassword} onChange={handlePasswordChange} id="new-reset-password"></input>
                <img alt="Open/closed eye for viewing password" className="password-img" onClick={toggleNewPasswordVisibility} src={newPasswordIsVisible ? closedEye : eye} style={{height: (newPasswordIsVisible ? '10px' : 'initial')}} id="new-pswd-img-reset"/>
                <label htmlFor="confirmed-reset-password"className="reset-password-confirmed-pswd-label">Confirm Password: <span className="required">*</span></label>
                <input type={getConfirmedPasswordType()} value={confirmedPassword} onChange={handleConfirmedPasswordChange} id="confirmed-reset-password"></input>
                <img alt="Open/closed eye for viewing password" className="password-img" onClick={toggleConfirmedPasswordVisibility} src={confirmedPasswordIsVisible ? closedEye : eye} style={{height: (confirmedPasswordIsVisible ? '10px' : 'initial')}} id="confirmed-pswd-img-reset"/>
                <button onClick={handleClick}>Change Password</button>
            </div>
        </div>
    )
}

export default PasswordReset;