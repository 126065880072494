import ChangePasswordForm from '../../components/changePasswordForm';
import ChangeEmailForm from '../../components/changeEmailForm';
import './index.css';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAlert } from '../../context/AlertContext';
import Title from '../../components/pageTitle';


function Account () {
    const navigate = useNavigate();
    const { triggerAlert } = useAlert();
    const [ loggedIn, setLoggedIn ] = useState(false);
    
    useEffect(() => {
        async function fetchData () {
            try {
                const response = await fetch('https://api.flashcard-revision.com/user/authenticate', {credentials: 'include'});
                if (response.status === 401) { navigate('/login') }
                else if (!response.ok) { 
                    const error = await response.json();
                    throw new Error(error.message);
                }
                else setLoggedIn(true);
            } catch (err) { triggerAlert(err.message, 'error'); };
        };  
        fetchData();
    }, []);

    async function handleDeleteAccount () {
        try {
            const confirm = window.confirm("Are you sure you want to delete your account? This will erase all records, information, subjects, and scores. This action cannot be reversed.");
            if (confirm) {
                const options = {method: 'POST', credentials: 'include', headers: {'Content-Type': 'application/json'}};
                const response = await fetch('https://api.flashcard-revision.com/user/account/delete', options);
                if (response.status === 401) { navigate('/login') }
                else if (!response.ok) {
                    const error = await response.json();
                    throw new Error(error.message);
                } else {
                    const result = await response.json();
                    triggerAlert(result.message, 'success');
                    navigate('/')
                }
            } else return;
        } catch (e) { triggerAlert(e.message, 'error'); };
    }

    async function handleLogout () {
        try {
            const options = {method: 'POST', credentials: 'include'};
            const response = await fetch('https://api.flashcard-revision.com/user/logout', options);
            if (response.status === 401) { navigate('/'); }
            else if (!response.ok) {
                const error = await response.json();
                throw new Error(error.message);
            } else {
                const result = await response.json();
                triggerAlert(result.message, 'success');
                navigate('/');
            }
        } catch (e) { triggerAlert(e.message, 'error'); };
    }

    if (loggedIn) {
        return (
            <div className="account-container">
                <Title title="My Account"/>
                <div className="account-form-container">
                    <ChangeEmailForm />
                    <ChangePasswordForm />
                </div>
                <div className="logout-container">
                    <button onClick={handleLogout}>Logout</button>
                </div>
                <div className="danger-zone">
                    <button onClick={handleDeleteAccount}>Delete account</button>
                </div>
            </div>
        )
    }
};

export default Account;