import { useEffect, useState } from "react";

function Question ({flashcard_type, question, setGapAnswer}) {
    const [ flashcardQuestion, setFlashcardQuestion ] = useState();
    
    useEffect(() => {
        if (flashcard_type === 'Fill-In-The-Gap') {
            setGapAnswer([]);
            const questionArray = question.split(' ');
            const numberOfGaps = Math.floor(questionArray.length / 5);
            const indices = [];
            while (indices.length < numberOfGaps) {
                const randomIndex = Math.floor(Math.random() * questionArray.length);
                if (!indices.includes(randomIndex)) {
                    indices.push(randomIndex);
                }
            }
            indices.sort((a, b) => a - b);
            const gapAnswer = [];
            indices.forEach((answerIndex, index) => {
                const answer = questionArray[answerIndex];
                gapAnswer[index] = answer;
                questionArray[answerIndex] = '_____';
            })
            setGapAnswer(gapAnswer);
            setFlashcardQuestion(questionArray.join(' '));
        } else {
            setFlashcardQuestion(question);
        }
    }, [question]);

    return <p>{flashcardQuestion}</p>
}

export default Question;