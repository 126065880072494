import { useSearchParams, NavLink, useNavigate } from "react-router-dom";
import { useAlert } from "../../context/AlertContext";
import { useState } from 'react';
import Title from "../../components/pageTitle";
import './index.css';

function AddFlashcard () {
    const [ searchParams ] = useSearchParams(); 
    const subjectId = searchParams.get('subjectId');
    const [ type, setType ] = useState('Standard');
    const [ question, setQuestion ] = useState('');
    const [ answer, setAnswer ] = useState('');
    const { triggerAlert } = useAlert();
    const navigate = useNavigate();

    async function handleSave () {
        try {   
            if (type !== 'Standard' && type !== 'Multiple Answer' && type !== 'Fill-In-The-Gap') {
                triggerAlert('Please provide a valid flashcard type', 'error');
            } else if (question.length < 12 || question.length > 175) {
                triggerAlert('Please provide a valid flashcard question between 12 and 175 characters in length', 'error');
            } else if (type === 'Multiple Answer' && !answer.includes(',')) {
                triggerAlert('Please provide comma separated answers for this flashcard', 'error');
            } else if (type !== 'Fill-In-The-Gap' && answer.length === 0) {
                triggerAlert('Please provide a valid answer for this flashcard', 'error');
            } else if (type === 'Multiple Answer' && (answer.split(',').length > 3 || answer.split(',').length < 2)) {
                triggerAlert('Please provide 2 or 3 answers for this flashcard, separated by commas', 'error');
            } else if (type === 'Standard' && (answer.length > 30 || answer.length < 1)) { 
                triggerAlert('Please provide an answer between 1 and 30 characters in length');
             } else {
                const formattedAnswers = answer.split(',').map(item => item.trim()).join(',');
                const body = {type, question, answer: (type === 'Multiple Answer' ? formattedAnswers : answer), subjectId};
                const options = {method: 'POST', credentials: 'include', headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body)};
                const response = await fetch('https://api.flashcard-revision.com/user/subjects/flashcards/add', options);
                if (response.status === 401) { navigate('/login') }
                else if (!response.ok) { 
                    const error = await response.json();
                    throw new Error(error.message); 
                } else {
                    const result = await response.json();
                    triggerAlert(result.message, 'success');
                    navigate(`/subject/edit?subjectId=${subjectId}`);
                }
            }
        } catch (err) { triggerAlert(err.message, 'error')};
    };

    function handleTypeSelect ({target}) {
        setType(target.value);
    }

    function handleChangeQuestion ({target}) {
        setQuestion(target.value);
    }

    function handleChangeAnswer ({target}) {
        setAnswer(target.value);
    }

    return (
        <>
            <div className="add-flashcard-container">
                <Title title="Add Flashcard" />
                <div className="add-flashcard-card">
                    <div className="radio-type-container">
                        <h4>Flashcard Type:</h4>
                        <input type="radio" id="standard" name="flashcard-type" value="Standard" onClick={handleTypeSelect} checked={type === 'Standard' ? true : false}></input>
                        <label htmlFor="standard">Standard</label>
                        <br/>
                        <input type="radio" id="multi" name="flashcard-type" value="Multiple Answer" onClick={handleTypeSelect} checked={type === 'Multiple Answer' ? true : false}></input>
                        <label htmlFor="multi">Multiple answer</label>
                        <br/>
                        <input type="radio" id="fill-in-the-gap" name="flashcard-type" value="Fill-In-The-Gap" onClick={handleTypeSelect} checked={type === 'Fill-In-The-Gap' ? true : false}></input>
                        <label htmlFor="fill-in-the-gap">Fill in the gap</label>  
                    </div>
                    <div className="add-flashcard-question-container">
                        <label htmlFor="question-input">Question</label>
                        <textarea id="question-input" className="add-flashcard-question" onChange={handleChangeQuestion} placeholder={type === 'Fill-In-The-Gap' ? 'Blanks will be decided automatically' : ''}></textarea>
                    </div>
                    {
                    type === 'Fill-In-The-Gap' 
                    ? null 
                    : <input type="text" placeholder={type === 'Multiple Answer' 
                    ? 'Answer One, Answer Two...'
                    : 'Answer' } className="add-flashcard-answer" onChange={handleChangeAnswer}></input>
                    }
                    <button className="add-flashcard-go-back-btn"><NavLink to={`/subject/edit?subjectId=${subjectId}`} className="add-flashcard-go-back-link">Go Back</NavLink></button>
                    <button className="add-flashcard-save-btn" onClick={handleSave}>Save</button>
                </div>
            </div>
        </>
    );
};

export default AddFlashcard;