import { NavLink } from 'react-router-dom';
import flashcards from '../../images/flashcards.png';
import avatar from '../../images/avatar.png';
import "./index.css";

function Menu () {
    return (
        <>
            <div className="menu-item-container">
                <NavLink className="menu-item-link" to="/subjects">
                    <img src={flashcards} alt="A set of cards" className="menu-item-icon"/>
                    <span>Subjects</span>
                </NavLink>
                <NavLink className="menu-item-link" to="/account">
                    <img src={avatar} alt="Avatar Icon" className="menu-item-icon"/>
                    <span>Account</span>
                </NavLink>
            </div>
        </>
    )
};

export default Menu;