import CustomNavLink from '../customNavLink/customNavLink';
import hamburger from "../../images/hamburger-icon.png"
import logo from "../../images/logo.png"
import SearchBar from '../searchBar';
import './index.css';

function Navigation () {
    return (
        <div className="navigation-container" style={{display: "grid"}}>
            <div className="navigation-logo-container">
                <img className="navigation-logo" alt="website logo" src={logo} />
            </div>
            <div className="navigation-title-container">
                <h4>Flashcard Revision</h4>
            </div>
            <SearchBar />
            <div className="navigation-hamburger-container">
                <CustomNavLink to="/menu"><img src={hamburger} alt="hamburger style menu icon" style={{width: 30}} id="hamburger-icon"/></CustomNavLink>
            </div>
        </div>
    )
}

export default Navigation;