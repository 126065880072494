import EditSubjectCard from '../../components/editSubjectCard';
import Flashcard from '../../components/flashcards';
import EditPublicCard from '../../components/editPublic';
import { NavLink, useSearchParams, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useAlert } from '../../context/AlertContext';
import Title from '../../components/pageTitle'
import './index.css';

function EditSubject () {
    const [ searchParams ] = useSearchParams();
    const [ flashcards, setFlashcards ] = useState([]);
    const [ subjectDetails, setSubjectDetails ] = useState({});
    const [ isOwner, setIsOwner ] = useState('');
    const [ loggedIn, setLoggedIn ] = useState(false);
    const subject_id = searchParams.get('subjectId');
    const { triggerAlert } = useAlert();
    const navigate = useNavigate();

    useEffect(() => {
        async function fetchData () {
            try {
                const response = await fetch(`https://api.flashcard-revision.com/user/subjects/flashcards?subjectId=${subject_id}`, {credentials: 'include'});
                if (response.status === 401) { navigate('/login') }
                else if (!response.ok) {
                    const error = await response.json();
                    throw new Error(error.message);
                } else {
                    const result = await response.json();
                    setFlashcards(result.data);
                    setLoggedIn(true);
                }
            } catch (err) { triggerAlert(err.message, 'error'); }; 
        };  
        fetchData();
    }, [])

    useEffect(() => {
        async function fetchData () {
            try {
                const response = await fetch(`https://api.flashcard-revision.com/user/subjects/details?subjectId=${subject_id}`, {credentials: 'include'});
                if (response.status === 401) { navigate('/login') }
                if (!response.ok) { 
                    const error = await response.json();
                    throw new Error(error.message); 
                } else {
                    const result = await response.json();
                    setSubjectDetails(result.data);
                }
            } catch (err) { triggerAlert(err.message, 'error'); };
        }
        fetchData();
    }, []);

    useEffect(() => {
        async function fetchData () {
            try {
                const response = await fetch(`https://api.flashcard-revision.com/user/subjects/is-owner?subjectId=${subject_id}`, {credentials: 'include'});
                if (response.status === 401) { navigate('/login') }
                if (!response.ok) {
                    const error = await response.json();
                    throw new Error(error.message);
                } else {
                    const result = await response.json();
                    setIsOwner(result.data);
                }
            } catch (err) { triggerAlert(err.message, 'error'); };
        }
        fetchData();
    }, [])

    function handleClickPrevious () {
        let item = document.querySelector(".item");
        let itemWidth = item.offsetWidth;
        document.querySelector(".list").scrollBy({ left: -itemWidth, behavior: "smooth" });
    }

    function handleClickNext () {
        let item = document.querySelector(".item");
        let itemWidth = item.offsetWidth;
        document.querySelector(".list").scrollBy({ left: itemWidth, behavior: "smooth" });    
    }

    function displayFlashcards () {
        return flashcards.map((flashcard, index) => {
            return (
                <Flashcard key={index} flashcardDetails={flashcard} handleDelete={deleteFlashcard} bgColor={subjectDetails.color} editable={true} isOwner={isOwner}/>
            )
        });
    }
    
    async function deleteFlashcard ({flashcardId}) {
        const confirmed = window.confirm('Are you sure you want to delete this flashcard?');
        if (!confirmed) return;
        try {
            const body = {flashcardId: flashcardId, subjectId: subject_id};
            const options = {method: 'POST', credentials: 'include', headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body)};
            const response = await fetch('https://api.flashcard-revision.com/user/subjects/flashcards/delete', options);
            if (!response.ok) {
                const error = await response.json();
                throw new Error(error.message);
            } else {
                const result = await response.json();
                triggerAlert(result.message, 'success');
                setFlashcards(prevFlashcards => prevFlashcards.filter(flashcard => flashcard.flashcardId !== parseInt(flashcardId)));
                navigate(0);
            }
        } catch (err) { triggerAlert(err.message, 'error'); };   
    }

    if (loggedIn) {
        return (
            <>
                <div className="subject-edit-container">
                    <Title title="Edit Subject"/>
                    <ul className="list">
                        {isOwner ? <EditSubjectCard subjectDetails={subjectDetails} /> : <EditPublicCard subjectDetails={subjectDetails}/>}
                        {displayFlashcards()}
                        {isOwner ? <li className="add-flashcard-item item">
                            <div className="add-flashcard-link-container">
                                <NavLink className="add-flashcard-link" to={`/subject/add?subjectId=${subject_id}`}>+</NavLink>
                                <NavLink className="add-flashcard-link" to={`/subject/add?subjectId=${subject_id}`}>Add Flashcard</NavLink>
                            </div>
                        </li> : null }
                    </ul>
                    <p className="navbutton previous" type="button" onClick={handleClickPrevious}></p>
                    <p className="navbutton next" type="button" onClick={handleClickNext}></p>
                </div>
            </>
        );
    }
}

export default EditSubject;