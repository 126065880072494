import { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useAlert } from '../../context/AlertContext';
import Title from '../../components/pageTitle';
import './index.css';

function SearchResults () {
    const navigate = useNavigate();
    const [ searchParams ] = useSearchParams();
    let term = searchParams.get('term');
    const [ searchResults, setSearchResults ] = useState(null);
    const { triggerAlert } = useAlert();

    useEffect(() => {
        async function fetchResults () {
            try {
                if (term.includes(' ')) triggerAlert('Please one use a single search term', 'error')
                else {
                    const response = await fetch(`https://api.flashcard-revision.com/search?term=${term}`, {credentials: 'include'});
                    if (response.status === 401) { navigate('/login'); }
                    else if (!response.ok) { 
                        const error = await response.json();
                        throw new Error(error.message);
                    };
                    const result = await response.json();
                    setSearchResults(result.data);
                }
            } catch (err) { triggerAlert(err.message, 'error'); };
        }  
        fetchResults();
    }, []);

    async function handleAddSubject (subjectId) {
        try {
            const body = {subjectId: subjectId};
            const options = {method: 'POST', credentials: 'include', headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body)};
            const response = await fetch('https://api.flashcard-revision.com/user/subjects/add', options);
            if (response.status === 401) { navigate('/login'); }
            else if (!response.ok) { 
                const error = await response.json();
                throw new Error(error.message);
            }
            const result = await response.json();
            triggerAlert(result.message, 'success');
            navigate(0);
        } catch (err) { triggerAlert(err.message, 'error'); };
    }

    function handleViewSubject (subjectId) {
        navigate(`/subjects/search/view?subjectId=${subjectId}`);
    }

    function renderResults () {
        return searchResults.map((result, index) => {
            return (
                <tr key={index}>
                    <td title={result.name}>{result.name.length > 25 ? result.name.substring(0, 22) + '...' : result.name}</td>
                    <td>{result.flashcard_count}</td>
                    <td>{result.user_collection_count}</td>
                    <td><button onClick={() => handleAddSubject(result.subject_id)} className="add-searched-subject">+</button></td>
                    <td><button onClick={() => handleViewSubject(result.subject_id)} className="view-searched-subject">View</button></td>
                </tr>
            )
        })
    }

    if (!searchResults && !term.includes(' ')) return <div className="search-results-container"><p>Loading subjects..</p></div>;
    else if (searchResults.length === 0 || term.includes(' ')) return <div className="search-results-container"><p>Sorry, we couldn't find anything! Try searching another term.</p></div>; 
    else if (searchResults.length > 0) {
        return (
            <div className="search-results-container">
                <Title title="Search Results"/>
                <table>
                    <thead>
                        <tr>
                            <th>Subject</th>
                            <th>Cards</th>
                            <th>Users</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderResults()}
                    </tbody>
                </table>
            </div>
        )
    }
}

export default SearchResults;