import { useState, useEffect } from 'react';
import AnswerFields from '../answerFields';
import Question from '../question';
//import './index.css';

function QuestionSide ({flashcard_type, question, answer, setIsQuestionSide, setCorrectGuess, bgColor, textColor}) {
    const [ gapAnswer, setGapAnswer ] = useState([]);
    const [ userAnswer, setUserAnswer ] = useState();
    const [ multipleUserAnswer, setMultipleUserAnswer ] = useState([]);

    useEffect(() => {
        setUserAnswer('');
        if (gapAnswer) {
            setMultipleUserAnswer(new Array(gapAnswer.length).fill(''));
        } else if (answer) {
            setMultipleUserAnswer(new Array(answer.length).fill(''));
        }
    }, [answer]);

    function checkAnswer () {
        if (flashcard_type === 'Standard') {
            if (answer.toString().toLowerCase() === userAnswer.toString().toLowerCase()) { setCorrectGuess(true); } 
            else { setCorrectGuess(false); };
            setIsQuestionSide(false);
        } else if (flashcard_type === 'Multiple Answer') {
            const target = answer.length;
            let total = 0;
            answer = answer.map(item => item.toLowerCase());
            for (let index in multipleUserAnswer) {
                if (answer.includes(multipleUserAnswer[index].toLowerCase())) { total++ };
            }
            if (total === target) { setCorrectGuess(true); }
            else { setCorrectGuess(false); };
            setIsQuestionSide(false);
        } else {
            if (multipleUserAnswer.length !== gapAnswer.length) {
                setCorrectGuess(false);
                setIsQuestionSide(false);
                return;
            }
            for (let index in multipleUserAnswer) {
                if (multipleUserAnswer[index].toLowerCase() !== gapAnswer[index].toLowerCase()) {
                   setCorrectGuess(false);
                   setIsQuestionSide(false);
                   return;
                }; 
            };
            setCorrectGuess(true);
            setIsQuestionSide(false);
        };
    };

    function updateUserAnswer ({target}) {
        setUserAnswer(target.value);
    };

    const updateMultipleUserAnswer = (index) => ({target}) => {
        setMultipleUserAnswer(multipleUserAnswer => {
            const newAnswers = [...multipleUserAnswer];
            newAnswers[index] = target.value;
            return newAnswers;
        });
    };

    function handleSkip () {
        setCorrectGuess(false);
        setIsQuestionSide(false);
    }

    return (
        <div className="current-flashcard-container" style={{backgroundColor: bgColor, color: textColor}}>
            <div className="flashcard-question-container">
                <Question 
                    flashcard_type={flashcard_type}
                    question={question}
                    setGapAnswer={setGapAnswer}
                />
            </div>
            <div className="answer-field-container">
                <AnswerFields 
                    flashcard_type={flashcard_type} 
                    answer={flashcard_type === 'Fill-In-The-Gap' ? gapAnswer : answer} 
                    updateUserAnswer={updateUserAnswer}
                    updateMultipleUserAnswer={updateMultipleUserAnswer}
                    userAnswer={userAnswer}
                    multipleUserAnswer={multipleUserAnswer}
                />
            </div>
            <button onClick={handleSkip} className="skip-question-button">Skip</button>
            <button onClick={checkAnswer} className="check-answer-button">Check</button>
        </div>
    );
}

export default QuestionSide;