import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAlert } from '../../context/AlertContext';
import eye from '../../images/eye.png';
import closedEye from '../../images/close-eye.png';
import './index.css';

function ChangePasswordForm () {
    const [ password, setPassword ] = useState('');
    const [ isPasswordVisible, setIsPasswordVisible ] = useState(false);
    const [ newPassword, setNewPassword ] = useState('');
    const [ isNewPasswordVisible, setIsNewPasswordVisible ] = useState(false);
    const [ confirmedPassword, setConfirmedPassword ] = useState('');
    const [ isConfirmPasswordVisible, setIsConfirmPasswordVisible ] = useState(false);
    const [ passwordIsEditable, setPasswordIsEditable ] = useState(false);
    const { triggerAlert } = useAlert();
    const navigate = useNavigate();

    useEffect(() => {
        setPasswordIsEditable(false);
        setIsPasswordVisible(false);
        setIsNewPasswordVisible(false);
        setIsConfirmPasswordVisible(false);
    }, [])

    function updatePasswordIsEditable () {
        setPasswordIsEditable(true);
    }

    function updateCurrentPassword ({target}) {
        setPassword(target.value);
    }

    function updateNewPassword ({target}) {
        setNewPassword(target.value);
    }

    function updateConfirmedPassword ({target}) {
        setConfirmedPassword(target.value);
    }

    async function savePassword () {
        try {
            if (confirmedPassword !== newPassword) {
                triggerAlert('New and confirmed passwords do not match!', 'error');
                setNewPassword('');
                setConfirmedPassword('');
            } else if (!confirmedPassword || !newPassword || !password) {
                triggerAlert('Please provide your current password, new password, and confirmed password!', 'error');
            } else if (confirmedPassword.length < 8 || newPassword.length < 8 || password.length < 8) {
                triggerAlert('Password must be at least 8 characters in length.', 'error');  
            } else {
                const body = {currentPassword: password, newPassword, confirmedPassword };
                const options = {method: 'POST', credentials: 'include', headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body)};
                const response = await fetch('https://api.flashcard-revision.com/user/password', options);
                if (!response.ok) { 
                    const error = await response.json();
                    throw new Error(error.message);
                }
                else {
                    const result = await response.json();
                    triggerAlert(result.message, 'success');
                    setPassword('');
                    setNewPassword('');
                    setConfirmedPassword('');
                    setPasswordIsEditable(false);
                }
            }
        } catch (err) { triggerAlert(err.message, 'error') };
    }

    function changeConfirmPasswordVisibility () {
        setIsConfirmPasswordVisible(!isConfirmPasswordVisible)
    }

    function changeNewPasswordVisibility () {
        setIsNewPasswordVisible(!isNewPasswordVisible)
    }

    function changePasswordVisibility () {
        setIsPasswordVisible(!isPasswordVisible)
    }
    
    return (
        <div className="account-password-container">
            {passwordIsEditable ? (
                <div className="password-input-container">
                    <div className="current-password-container">
                        <label htmlFor="current-password" className="current-password-label">Password: <span className="required">*</span></label>
                        <input type={isPasswordVisible ? 'text' : 'password'} id="current-password" onChange={updateCurrentPassword} value={password} required></input>
                        <img alt="Open/closed eye for viewing password" className="password-img" onClick={changePasswordVisibility} src={isPasswordVisible ? closedEye : eye} style={{height: (isNewPasswordVisible ? '10px' : 'initial')}}/>
                    </div>
                    <div className="new-password-container">
                        <label htmlFor="new-password" className="new-password-label">New Password: <span className="required">*</span></label>
                        <input type={isNewPasswordVisible ? 'text' : 'password'} id="new-password" onChange={updateNewPassword} value={newPassword} required></input>
                        <img alt="Open/closed eye for viewing password" style={{height: (isNewPasswordVisible ? '10px' : 'initial')}} className="password-img" onClick={changeNewPasswordVisibility} src={isNewPasswordVisible ? closedEye : eye}/>
                    </div>
                    <div className="confirmed-password-container">
                        <label htmlFor="confirmed-password" className="confirmed-password-label">Repeat Password: <span className="required">*</span></label>
                        <input type={isConfirmPasswordVisible ? 'text' : 'password'} id="confirmed" onChange={updateConfirmedPassword} value={confirmedPassword} required></input>
                        <img className="password-img" style={{height: (isConfirmPasswordVisible ? '10px' : 'initial')}} onClick={changeConfirmPasswordVisibility} src={isConfirmPasswordVisible ? closedEye : eye} alt="Open/closed eye for viewing password"/>
                    </div>
                </div>
            ) : null}
            <button onClick={passwordIsEditable ? savePassword : updatePasswordIsEditable}>{passwordIsEditable ? 'Save new Password' : 'Change Password'}</button>
        </div>
    )
}

export default ChangePasswordForm;