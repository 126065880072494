import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import './index.css';
import flashcards from '../../images/flashcards.png';
import community from '../../images/community.png';
import mobile from '../../images/mobile.png';
import settings from '../../images/settings.png';
import upgrade from '../../images/upgrade.png';
import { useNavigate } from 'react-router-dom';
import { useAlert } from '../../context/AlertContext';


function Homepage () {
    const navigate = useNavigate();
    const { triggerAlert }= useAlert();
    const [ loggedIn, setLoggedIn ] = useState(false);

    useEffect(() => {
        async function checkAuth () {
            try {
                const response = await fetch('https://api.flashcard-revision.com/user/authenticate', {credentials: 'include'});
                if (response.status === 401) return;
                else if (!response.ok) { 
                    const error = await response.json();
                    throw new Error(error.message);
                }
                else { 
                    navigate('/subjects'); 
                    setLoggedIn(true);
                };
            } catch (err) { triggerAlert(err.message); };
        }
        checkAuth();
    }, []);

    useEffect(() => {
        const bmcButton = document.getElementsByClassName('bmc-btn-container')[0];
        bmcButton.style.display = 'none';
        return () => { bmcButton.style.display = 'block'; };
    }, [])

    if (!loggedIn) {
        return (
            <>
                <div className="hero-container">
                    <h1 className="hero-main-title">Master Your Subjects</h1>
                    <h4 className="hero-sub-title">Built by students, for students</h4>
                    <div className="cta-container">
                    <button className="cta-button"><NavLink to="/subjects/" className="hero-start-button">Get Started!</NavLink></button>
                    </div>
                </div>
                <div className="main-content">
                    <h2 className="main-content-h2">Easy revision, available for all!</h2>
                    <div className="main-content-feature-container feature" id="feature-flashcards">
                        <img className="main-content-feature-image" src={flashcards} alt="set of 3 cards" />
                        <p>Standard, multiple choice, and fill in the gap flashcards!</p>
                    </div>
                    <div className="main-content-feature-container feature" id="feature-sharing">
                        <img className="main-content-feature-image" src={community} alt="set of 3 avatars" />
                        <p>Share your subjects with the community!</p>
                    </div>
                    <div className="main-content-feature-container feature" id="feature-mobile">
                        <img className="main-content-feature-image" src={mobile} alt="mobile with tick" />
                        <p>Mobile friendly, study on the go!</p>
                    </div>
                    <div className="main-content-feature-container feature" id="feature-customizable">
                        <img className="main-content-feature-image" src={settings} alt="settings cog" />
                        <p>Customize your flashcard subjects with names, colors, and tags!</p>
                    </div>
                    <div className="main-content-feature-container feature" id="feature-tiers">
                        <img className="main-content-feature-image" src={upgrade} alt="upgrade arrow icon" />
                        <p>Self-funded by students for students, <NavLink to="/subjects/" className="main-content-feature link">get started now</NavLink> for <b>free!</b></p>
                    </div>
                </div>
            </>
        );
    }
};

export default Homepage;