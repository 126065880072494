import { NavLink } from 'react-router-dom';
import './index.css';

function Footer () {
    return (
        <footer className="footer">
            <div className="footer-policies-container">
                <NavLink className="policies-link" to="/policies/privacy">Privacy</NavLink>
                <NavLink className="policies-link" to="/policies/cookies">Cookies</NavLink>
            </div>
            <div className="footer-company-container">
                <p>© 2024 Flashcard Revision</p>
            </div>
        </footer>
    )
}

export default Footer;