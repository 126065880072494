import './App.css'
import { RouterProvider, createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom';
import Root from './components/root/index.js';
import Homepage from './pages/home/index.js';
import Menu from './pages/menu/index.js';
import Subjects from './pages/flashcardSubjects/subjects.js';
import CreateSubject from './pages/createSubject/index.js';
import EditSubject from './pages/editSubject/index.js';
import AddFlashcard from './pages/addFlashcard/index.js';
import RunFlashcards from './pages/start/index.js';
import Account from './pages/account/';
import ErrorFallback from './components/errorFallback/index.js';
import SearchResults from './pages/searchResults';
import { AlertProvider } from './context/AlertContext.js';
import CustomAlert from './components/customAlert/index.js';
import SearchedSubject from './pages/viewSearchedSubject/index.js';
import Login from './pages/login';
import PasswordResetRequest from './pages/passwordResetRequest/index.js';
import PasswordReset from './pages/passwordReset/index.js';
import Privacy from './pages/privacy/index.js';
import Cookies from './pages/cookies/index.js';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Root/>} errorElement={<ErrorFallback />}>
      <Route index element={<Homepage />}></Route>
      <Route path="/subjects" element={<Subjects />}></Route>
      <Route path="/subject/edit/" element={<EditSubject />}></Route>
      <Route path="/subject/add" element={<AddFlashcard />}></Route>
      <Route path="/subject/create" element={<CreateSubject />}></Route>
      <Route path="/subject/start" element={<RunFlashcards />}></Route>
      <Route path="/subjects/search/" element={<SearchResults />}></Route>
      <Route path="/menu" element={<Menu />}></Route>
      <Route path="/account" element={<Account />}></Route>
      <Route path="/login" element={<Login />}></Route>
      <Route path="/login/reset" element={<PasswordResetRequest />}></Route>
      <Route path="/user/password/reset" element={<PasswordReset />}></Route>
      <Route path="/policies/privacy" element={<Privacy />}></Route>
      <Route path="/policies/cookies" element={<Cookies />}></Route>
      <Route path="/subjects/search/view" element={<SearchedSubject />}></Route>
    </Route>
  )
);

function App() {
  return (
    <AlertProvider>
      <RouterProvider router={router}/>
      <CustomAlert />
    </AlertProvider>
  );
}

export default App;
