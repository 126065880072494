import { NavLink, useLocation, useNavigate } from 'react-router-dom';

function CustomNavLink ({to, children, ...props }) {
    const location = useLocation();
    const navigate = useNavigate();

    function handleClick (e) {
        if (location.pathname === to) {
            e.preventDefault();
            navigate(-1);
        }
    };

    return (
        <NavLink to={to} onClick={handleClick} {...props}>{children}</NavLink>
    )
}

export default CustomNavLink;