import React, {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import Navigation from '../navigation/index';
import Footer from '../footer/index';

function ErrorFallback () {
    const [ remainingTime, setRemainingTime ] = useState(5);
    const navigate = useNavigate();
    
    useEffect(() => {
        if (remainingTime === 0) navigate('/');
        const timer = setTimeout(() => { setRemainingTime(remainingTime => remainingTime - 1)}, 1000);
        return () => clearInterval(timer);
    }, [remainingTime]);

    return (
        <div>
            <Navigation />
            <div style={{height: '85vh', backgroundColor: '#f5f5f5', textAlign: 'center', paddingTop: '1rem'}}>
                <h2>Oops, something went wrong!</h2>
                <p>Redirecting you in {remainingTime}</p>
            </div>
            <Footer />
        </div>
    )
}

export default ErrorFallback;