import './index.css';
import Carousel from '../../components/subjectCarousel/index.js';
import Title from '../../components/pageTitle/index.js';

function Subjects () {
    return (
        <>
            <div className="my-subjects-container">
                <Title title="My Subjects"/>
                <Carousel />
            </div>
        </>
    );
};

export default Subjects;