import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAlert } from '../../context/AlertContext';
import './index.css';

function ChangeEmailForm () {
    const [ email, setEmail ] = useState('');
    const [ emailIsEditable, setEmailIsEditable ] = useState(false);
    const { triggerAlert } = useAlert();
    const navigate = useNavigate();

    useEffect(() => {
        async function fetchData () {
            try {
                const response = await fetch('https://api.flashcard-revision.com/user/details', {credentials: 'include'});
                if (response.status === 401) { navigate('/login') }
                else if (!response.ok) { 
                    const error = await response.json();
                    throw new Error(error.message);
                }
                else {
                    const result = await response.json();
                    setEmail(result.data);
                    setEmailIsEditable(false);
                }
            } catch (err) { triggerAlert(err.message, 'error'); };
        } 
        fetchData();
    }, []);

    function updateEmail ({target}) {
        setEmail(target.value);
    }

    function updateEmailIsEditable () {
        setEmailIsEditable(true);
    }

    async function saveEmail () {
        try {
            if ((email.length === 0) || (!email.includes('@'))) {
                triggerAlert('Please provide a valid email address', 'error');
            } else {
                const body = {email};
                const options = {method: 'POST', credentials: 'include', headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body)};
                const response = await fetch('https://api.flashcard-revision.com/user/email', options);
                if (response.status === 401) navigate('/login')
                else if (!response.ok) {
                    const error = await response.json();
                    throw new Error(error.message); 
                } else {
                    const result = await response.json();
                    triggerAlert(result.message, 'success');
                    setEmailIsEditable(false);
                }   
            }
        } catch (err) { triggerAlert(err.message, 'error'); };
    }

    return (
        <div className="account-email-container">
            <label htmlFor="email-input">Email: </label>
            <input type="email" id="email-input" value={email} disabled={!emailIsEditable} onChange={updateEmail} required></input>
            <button onClick={emailIsEditable ? saveEmail : updateEmailIsEditable}>{emailIsEditable ? 'Save' : 'Edit'}</button>
        </div>
    )
}

export default ChangeEmailForm;