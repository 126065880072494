import { useState, useEffect } from 'react';
import { useSearchParams, NavLink, useNavigate } from 'react-router-dom';
import { useAlert } from '../../context/AlertContext';
import pen from '../../images/pen.png';
import tick from '../../images/tick.png';
import './index.css';

function EditSubjectCard ({subjectDetails}) {
    const [ searchParams ] = useSearchParams();
    const subjectId = searchParams.get('subjectId');
    const [ subjectTitle, setSubjectTitle ] = useState('');
    const [ subjectColor, setSubjectColor ] = useState('');
    const [ textColor, setTextColor ] = useState('rgb(15, 51, 71');
    const [ titleIsEditable, setTitleIsEditable ] = useState(false);
    const [ isPrivate, setIsPrivate ] = useState(true);
    const [ tags, setTags ] = useState([]);
    const [ loggedIn, setLoggedIn ] = useState([]);
    const { triggerAlert } = useAlert();
    const navigate = useNavigate();

    useEffect(() => {
        async function authenticate () {
            try {
                const response = await fetch('https://api.flashcard-revision.com/user/authenticate', {credentials: 'include'});
                if (response.status === 401) { navigate('/login'); }
                else if (!response.ok) {
                    const error = await response.json();
                    throw new Error(error.message);
                } else setLoggedIn(true);
            } catch (err) { triggerAlert(err.message, 'error'); };
        }   
        authenticate();
    }, [])

    useEffect(() => {
        setSubjectTitle(subjectDetails.name);
        setSubjectColor(subjectDetails.color);
        setIsPrivate(subjectDetails.private === 0 ? false : true);
        if (subjectDetails.tags) setTags(subjectDetails?.tags.split(','));
        getTextColor(subjectDetails.color);
    }, [subjectDetails.subject_id]);

    function handleTitleChange ({target}) {
        const value = target.value;
        setSubjectTitle(value);
    }

    function getTextColor (color) {
        setTextColor(color === 'grey' || color === 'green' || 
               color === 'red' || color === 'blue'
               ? 'white' : 'rgb(15, 51, 71)');
    }

    function setTitleEditable () {
        setTitleIsEditable(titleIsEditable ? false : true);
    }

    function handleColorChange ({target}) {
        const color = target.value;
        setSubjectColor(color);
        getTextColor(color);
    }

    function handleSetPrivate () {
        setIsPrivate(!isPrivate)
    }

    function updateTags ({target}) {
        const targetIndex = target.getAttribute('data-index');
        const updatedTags = [...tags];
        updatedTags[targetIndex] = target.value;
        setTags(updatedTags);
    }

    function tagInputs () {
        return (
            <div className="edit-subject-tags-container">
                <p>Tags: </p>
                {tags.map((input, index) => {
                    if (index === 0) return <input type="text" data-index={index} value={input} onChange={updateTags} key={index}></input>
                    else if (tags[index-1]) {;
                        return <input type="text" data-index={index} value={input} onChange={updateTags} key={index}></input>
                    } else return null
                })}
            </div>
        );
    } 

    async function handleDelete () {
        // Confirm Deletion
        const confirmed = window.confirm('Are you sure you want to delete this subject?');
        if (!confirmed) return;
        try {
            const body = {subjectId};
            const options = {method: 'POST', credentials: 'include', headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body)};
            const response = await fetch('https://api.flashcard-revision.com/user/subjects/delete', options);
            if (!response.ok) { 
                const error = await response.json();
                throw new Error(error.message);
            } else {
                const result = await response.json();
                triggerAlert(result.message, 'success');
                navigate('/subjects');
            }
        } catch (err) { triggerAlert(err.message, 'error')};
    }

    async function handleSave () {
        try {
            if (subjectTitle.length > 30 || subjectTitle.length < 3) {
                triggerAlert('Please provide a subject title between 3 and 30 characters in length', 'error');
            } else if (subjectColor !== 'pink' && subjectColor !== 'blue' && subjectColor !== 'yellow' && subjectColor !== 'green' && subjectColor !== 'grey' && subjectColor !== 'red') {
                triggerAlert('Please provide a valid subject color', 'error');
            } else if (typeof isPrivate !== 'boolean') {
                triggerAlert('Please provide a valid value of the subjects private status', 'error');
            } else {
                const body = {subjectName: subjectTitle, subjectColor, isPrivate, subjectId, tags: tags};
                const options = {method: 'POST', credentials: 'include', headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body)};
                const response = await fetch('https://api.flashcard-revision.com/user/subjects/edit', options);
                if (!response.ok) { 
                    const error = await response.json();
                    throw new Error(error.message); 
                } else {
                    const result = await response.json();
                    triggerAlert(result.message, 'success');
                    window.location.reload();
                }
            }
        } catch (err) { triggerAlert(err.message, 'error'); };
    }

    function addCard () {
        navigate(`/subject/add?subjectId=${subjectId}`);
    }

    if (loggedIn) {
        return (
            <li className="item edit-subject-card" style={{backgroundColor: subjectColor}}>
                <div className="edit-subject-flashcard" style={{color: textColor}}>
                    <div className="subject-title-container">
                        {titleIsEditable ? <input className="subject-title-text" type="text" placeholder={subjectTitle} onChange={handleTitleChange}></input> : <h3 className="subject-title-text" onClick={setTitleEditable}>{subjectTitle}</h3>}
                        {titleIsEditable ? <img src={tick} onClick={setTitleEditable} alt="Tick for saving title"/> : <img src={pen} onClick={setTitleEditable} style={{width: 15}} alt="Pen for editing title"/>}
                    </div>
                    {tagInputs()}
                    <div className="edit-subject-radio-container">
                        <label >Color:</label>
                        <div className="red">
                            <input className="subject-color" type="radio" name="subjectColor" value="red" onClick={handleColorChange}></input>
                        </div>
                        <div className="pink">
                            <input className="subject-color pink" type="radio" name="subjectColor" value="pink" onClick={handleColorChange}></input>
                        </div>
                        <div className="green">
                            <input className="subject-color green" type="radio" name="subjectColor" value="green" onClick={handleColorChange}></input>
                        </div>
                        <div className="blue">
                            <input className="subject-color blue" type="radio" name="subjectColor" value="blue" onClick={handleColorChange}></input>
                        </div>
                        <div className="yellow">
                            <input className="subject-color yellow" type="radio" name="subjectColor" value="yellow" onClick={handleColorChange}></input>
                        </div>
                        <div className="grey">
                            <input className="subject-color grey" type="radio" name="subjectColor" value="grey" onClick={handleColorChange}></input>
                        </div>
                    </div>
                    <div className="shared-status-container">
                        <label htmlFor="sharedStatus">Private: </label>
                        <input type="checkbox" id="sharedStatus" checked={isPrivate} style={{accentColor: textColor}} onChange={handleSetPrivate}></input>
                    </div>
                    <div className="subject-edit-delete-container">
                        <button onClick={handleDelete}>Delete</button>
                    </div>
                    <div className="subject-edit-save-container">
                        <button onClick={handleSave}>Save</button>
                    </div>
                    <div className="subject-edit-add-flashcard-container">
                        <button onClick={addCard}>Add Card</button>
                    </div>    
                </div>
            </li>
        )
    }
}

export default EditSubjectCard;