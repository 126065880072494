import { useEffect } from 'react';
import './index.css';

function AnswerSide ({next, correctGuess, updateScore, answer, question, flashcard_type, bgColor, textColor}) {
    useEffect(() => {
        const resetTimer = setTimeout(() => {
            if (correctGuess) { updateScore(); }
            else { next(); };
        }, 7500);
        return () => clearTimeout(resetTimer);
    }, [])

    function renderAnswer () {
        if (flashcard_type === 'Standard') {
            return (
                <div className="reverse-flashcard-answer-container">
                    <p>{question}</p>
                    <p>{answer}</p>
                </div>
            )
        } else if (flashcard_type === 'Multiple Answer') {
            return (
                <div className="reverse-flashcard-answer-container">
                    <p>{question}</p>
                    <p>{answer.join(', ')}</p>
                </div>
            )
        } else {
            return (
                <div className="reverse-flashcard-answer-container">
                    <p>{question}</p>
                </div>
            )
        }
    }

    return (
        <div className="reverse-flashcard-container" style={{backgroundColor: bgColor, color: textColor}}>
            <p className="reverse-flashcard-result">{correctGuess ? 'Correct!' : 'Incorrect!'}</p>
            {renderAnswer()}
            <button className="reverse-flashcard-next-button" onClick={correctGuess ? updateScore : next}>Next</button>
        </div>
    )
}

export default AnswerSide;