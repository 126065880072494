function AnswerFields ({
        flashcard_type, answer, updateUserAnswer, 
        updateMultipleUserAnswer, userAnswer, 
        multipleUserAnswer
    }) {

    function formatAnswerFields () {
        if ((flashcard_type === 'Fill-In-The-Gap') || (flashcard_type === 'Multiple Answer')) {
            return answer.map((item, index) => {
                return <input key={`${index}`} className="flashcard-answer-input" type="text" value={multipleUserAnswer[index] || ''} onChange={updateMultipleUserAnswer(index)}></input>
            })
        } else {
            return <input className="flashcard-answer-input" type="text" value={userAnswer || ''} onChange={updateUserAnswer}></input>
        }
    } 

    return formatAnswerFields();
}

export default AnswerFields;