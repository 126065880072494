import React, { createContext, useState, useContext } from 'react';

const AlertContext = createContext();

export const AlertProvider = ({ children }) => {
    const [ message, setMessage ] = useState('');
    const [ alertType, setAlertType ] = useState('');
    const [ bgColor, setBgColor ] = useState('');

    const triggerAlert = (message, type = 'error') => {
        setMessage(message);
        setAlertType(type);
        setBgColor(type === 'success' ? '#4BB543' : 'red');

        setTimeout(() => {
            clearAlert();
        }, 6000);
    };

    const clearAlert = () => {
        setMessage('');
        setAlertType('');
        setBgColor('');
    };

    return (
        <AlertContext.Provider value={{ message, alertType, bgColor, triggerAlert, clearAlert}}>
            {children}
        </AlertContext.Provider>
    )
};

export const useAlert = () => useContext(AlertContext);