import { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useAlert } from '../../context/AlertContext';
import './index.css';

function Carousel () {
    const [ subjects, setSubjects ] = useState([]);
    const [ loggedIn, setLoggedIn ] = useState(false);
    const { triggerAlert } = useAlert();
    const navigate = useNavigate();

    useEffect(() => {
        async function fetchData () {
            try {
                const response = await fetch('https://api.flashcard-revision.com/user/subjects', {credentials: 'include'});
                if (response.status === 401) navigate('/login')
                else if (!response.ok) {
                    const error = await response.json();
                    throw new Error(error.message);
                }
                else {
                    const result = await response.json();
                    result.data.sort((a, b) => a.name.localeCompare(b.name));
                    setSubjects(result.data); 
                    setLoggedIn(true);
                }
            } catch (err) { triggerAlert(err.message, 'error'); };
        }
        fetchData();
    }, [])

    function handleClickPrevious () {
        let item = document.querySelector(".item");
        let itemWidth = item.offsetWidth;
        document.querySelector(".list").scrollBy({ left: -itemWidth, behavior: "smooth" });
    }

    function handleClickNext () {
        let item = document.querySelector(".item");
        let itemWidth = item.offsetWidth;
        document.querySelector(".list").scrollBy({ left: itemWidth, behavior: "smooth" });
    }

    function getTextColor ({color}) {
        return color === 'grey' || color === 'green' || 
               color === 'red' || color === 'blue'
               ? 'white' : 'rgb(15, 51, 71)';
    }

    function subjectItems () {
        return subjects.map((subject, index) => {
            const textColor = getTextColor(subject);
            return (
                <li className="item" style={{backgroundColor: subject.color, color: textColor}} key={index}>
                    <div className="content">
                        <p className="subject-title">{subject.name}</p>
                        <p className="subject-score">Best Score: {subject.score}%</p>
                        <p className="subject-share-status">{subject.private ? 'Private' : 'Public'}</p>
                        <button className="subject-edit-btn">
                            <NavLink to={`/subject/edit/?subjectId=${subject.subject_id}`} className="subject-edit-btn-link">Edit</NavLink>
                        </button>
                        <button className="subject-start-btn">
                            <NavLink to={`/subject/start?subjectId=${subject.subject_id}`} className="subject-start-btn-link">Start!</NavLink>
                        </button>
                    </div>
                </li>
            )
        });
    };

    if (loggedIn) {
        return (
            <div className="list-wrapper">
                <ul className="list">
                    <li className="item first-subject-item" style={{backgroundColor: '#ccc'}}>
                        <div className="content">
                            <button className="create-new-subject-btn">
                                <NavLink to="/subject/create" className="create-new-subject-link">+<br/><span>Create New Subject</span></NavLink>
                            </button>
                        </div>
                    </li>
                    {subjectItems()}
                </ul>
                <span className="navbutton previous" type="button" onClick={handleClickPrevious}></span>
                <span className="navbutton next" type="button" onClick={handleClickNext}></span>
            </div>
        )
    }
};

export default Carousel;