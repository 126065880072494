import { useState } from 'react';
import { useAlert } from '../../context/AlertContext';
import Title from '../../components/pageTitle';
import './index.css';

function PasswordResetRequest () {
    const [ email, setEmail ] = useState('');
    const [ sent, setSent ] = useState(false);
    const { triggerAlert } = useAlert();

    function handleEmailChange ({target}) {
        setEmail(target.value);
    }

    async function handleClick () {
        try {
            const body = {email};
            const options = {method: 'POST', credentials: 'include', headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body)}
            const response = await fetch(`https://api.flashcard-revision.com/user/password/forgot`, options);
            if (!response.ok) {
                const error = await response.json();
                throw new Error(error.message);
            } else {
                const result = await response.json();
                triggerAlert(result.message, 'success');
                setSent(true);
            }
        } catch (err) { triggerAlert(err.message, 'error'); }; 
    }

    return (
        <div className="password-reset-form-container">
            <Title title="Reset Your Password"/>
            <div className="password-reset-form">
                {sent ? null : <label htmlFor="reset-email" className="password-reset-form-email-label">Email: <span className="required">*</span></label> }
                {sent ? null : <input type="email" value={email} onChange={handleEmailChange} className="password-reset-form-email-input"/> }
                {sent ? <p className="password-reset-junk-tip">Reset password link has been sent.<br/><br/><span>Not received email? Check your junk folder</span></p> : <button onClick={handleClick} className="password-reset-submit-button">Reset Password</button> }
            </div>
        </div>
    )
};

export default PasswordResetRequest;