import { useEffect, useState } from 'react';
import QuestionSide from '../questionSide';
import AnswerSide from '../answerSide';
import './index.css';

function CurrentFlashcard ({flashcard_type, question, answer, next, updateScore, bgColor, textColor}) {
    const [ isQuestionSide, setIsQuestionSide ] = useState(true);
    const [ correctGuess, setCorrectGuess ] = useState(false);
    
    useEffect(() => {
        setIsQuestionSide(true);
        setCorrectGuess(false);
    }, [question, answer]);

    function renderCardSide () {
        if (isQuestionSide) {
            return <QuestionSide flashcard_type={flashcard_type} answer={answer} question={question} next={next} setIsQuestionSide={setIsQuestionSide} setCorrectGuess={setCorrectGuess} bgColor={bgColor} textColor={textColor}/>;
        } else {
            return <AnswerSide correctGuess={correctGuess} next={next} updateScore={updateScore} answer={answer} question={question} flashcard_type={flashcard_type} bgColor={bgColor} textColor={textColor}/>;
        }
    }

    return renderCardSide()
}

export default CurrentFlashcard;